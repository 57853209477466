// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhooks2-module__DJanZoKy4JdOojIf9eLh{display:flex;align-items:center;width:100%;padding-top:12px}.OutgoingWebhooks2-module__P7_IvAgz5UkW8LYun33d{display:flex;align-items:baseline}.OutgoingWebhooks2-module__VTcJ0kZPk9UXiNMT_5h0{display:flex;flex-direction:column;width:225px;border-radius:2px}.OutgoingWebhooks2-module__si7zrmYS1Z28YaK3TQMA{padding:8px;display:flex;align-items:center;flex-direction:row;flex-shrink:0;white-space:nowrap;border-left:2px solid rgba(0,0,0,0);cursor:pointer;min-width:84px;display:flex;gap:8px;flex-direction:row}.OutgoingWebhooks2-module__si7zrmYS1Z28YaK3TQMA:hover{background:var(--cards-background)}", "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks_2/OutgoingWebhooks2.module.scss"],"names":[],"mappings":"AAAA,gDACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,gBAAA,CAGF,gDACE,YAAA,CACA,oBAAA,CAGF,gDACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAGF,gDACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CACA,kBAAA,CACA,mCAAA,CACA,cAAA,CACA,cAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,sDACE,kCAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding-top: 12px;\n}\n\n.header__title {\n  display: flex;\n  align-items: baseline;\n}\n\n.hamburgerMenu {\n  display: flex;\n  flex-direction: column;\n  width: 225px;\n  border-radius: 2px;\n}\n\n.hamburgerMenu__item {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  flex-shrink: 0;\n  white-space: nowrap;\n  border-left: 2px solid transparent;\n  cursor: pointer;\n  min-width: 84px;\n  display: flex;\n  gap: 8px;\n  flex-direction: row;\n\n  &:hover {\n    background: var(--cards-background);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OutgoingWebhooks2-module__DJanZoKy4JdOojIf9eLh",
	"header__title": "OutgoingWebhooks2-module__P7_IvAgz5UkW8LYun33d",
	"hamburgerMenu": "OutgoingWebhooks2-module__VTcJ0kZPk9UXiNMT_5h0",
	"hamburgerMenu__item": "OutgoingWebhooks2-module__si7zrmYS1Z28YaK3TQMA"
};
export default ___CSS_LOADER_EXPORT___;
